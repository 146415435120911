<template>
  <div class="RealplayerMobile">
    <!-- 头部问题及banner -->
    <div class="banner" :style="{backgroundImage:'url(' + bannerBack + ')'}">
      <div class="t-c c-b text1">RealPlayer 移动版</div>
      <div class="t-c text2">必备的 RealPlayer 移动版！</div>
      <div class="scancode pc-type">
        <div class="scancode-google" @click="openLink(toGoogleLink)">
          <img src="@/img/googleplay.svg" alt="">
            <img src="@/img/googleplay_QR.png" alt="">
        </div>
        <div class="scancode-appstore"  @click="openLink(toAppStoreLink)">
          <img src="@/img/appstore.svg" alt="">
          <img src="@/img/appstore_QR.svg" alt="">
        </div>
      </div>
      <div class="scancode mobile-type">
        <div class="scancode-google" @click="openLink(toGoogleLink)" v-if="type == 'andriod'">
          <img src="@/img/googleplay.svg" alt="">
          <img src="@/img/googleplay_QR.png" alt="">
        </div>
        <div class="scancode-appstore"  @click="openLink(toAppStoreLink)" v-if="type == 'ios'">
          <img src="@/img/appstore.svg" alt="">
          <img src="@/img/appstore_QR.svg" alt="">
        </div>
      </div>
    </div>

    <!-- 内容介绍部分 -->
    <div class="content">
      <abort-real-content :show-list="list"></abort-real-content>
      <div class="t-c mobile-show-title">使用 RealPlayer 移动版完善您的体验</div>
      <div class="scancode pc-type">
        <div class="scancode-google1" @click="openLink(toGoogleLink)">
            <img src="@/img/googleplay.svg" alt="">
            <img src="@/img/googleplay_QR.png" alt="">
        </div>
        <div class="scancode-appstore1"  @click="openLink(toAppStoreLink)">
            <img src="@/img/appstore.svg" alt="">
            <img src="@/img/appstore_QR.svg" alt="">
        </div>
      </div>
      <div class="scancode mobile-type">
        <div class="scancode-google1" @click="openLink(toGoogleLink)"  v-if="type == 'andriod'">
            <img src="@/img/googleplay.svg" alt="">
            <img src="@/img/googleplay_QR.png" alt="">
        </div>
        <div class="scancode-appstore1"  @click="openLink(toAppStoreLink)"  v-if="type == 'ios'">
            <img src="@/img/appstore.svg" alt="">
            <img src="@/img/appstore_QR.svg" alt="">
        </div>
      </div>
      <div class="t-c text3">使用照相手机扫描二维码，安装应用程序</div>
    </div>

    <!-- 底部mobile模块 -->
    <div class="mobile-show">
      <div class="t-c mobile-show-title">完善您在电脑上使用 RealPlayer 的体验</div>
      <!-- 文字模块 -->
      <div class="mobile-show-content">
        <!-- 右侧图片 -->
        <div class="msc-r">
          <img src="@/img/new_mobile06.png" alt="">
        </div>
        <!-- 左侧模块 -->
        <div class="msc-l">
          <div class="text5">RealPlayer Windows 版</div>
          <div class="p_ text6" @click="openLink('/realplayer',true)">了解更多 ›</div>
          <div class="text7">最快捷、最简单的电脑媒体播放器和管理器。一键从网站下载视频！</div>
          <download-btn from="realplayer_mobile" style="margin:0 0 79px;width:80% !important;"></download-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AbortRealContent from "@/components/RealContent.vue"
  import AbortReal from "@/components/AbortReal.vue"
  import DownloadBtn from "@/components/DownloadBtn.vue"
  import settingLink from '@/js/global.js'
  export default {
   name: 'RealplayerMobile',
    data(){
      return{
        toGoogleLink:settingLink.googleLink,
        toAppStoreLink:settingLink.appStoreLink,
        bannerBack:require('@/img/new_realplayer_mobile.png'),
        // 模块展示内容
        list:{
          setting:{
            page:'realplayer_mobile',
            type:false,// 是否带下载
          },
          content:[
            {
              img:require('@/img/new_mobile01.png'),
              page:'real',
              type:true,// 是否带下载
              direction:'left',// 图片在左or右 left  right
              list_content:[
                {text:'全新！',type:'is_blue', },
                {text:'一键下载到手机！',type:'is_b_title', },
                {text:"最快，最简单，最有趣的方式来收藏你喜欢的视频。",type:'is_content', },
              ]
            },
            {
              img:require('@/img/new_mobile02.png'),
              direction:'right',// 图片在左or右 left  right
              list_content:[
                {text:'全新！',type:'is_blue', },
                {text:'手机端和电脑端同步',type:'is_b_title', },
                {text:"支持手机视频直接上传备份到电脑端。",type:'is_content', },
                {text:'支持手机端直接访问并播放电脑端媒体视频库。',type:'is_content', },
              ]
            },
            {
              img:require('@/img/new_mobile03.png'),
              page:'real',
              type:true,// 是否带下载
              direction:'left',// 图片在左or右 left  right
              list_content:[
                {text:'全新！',type:'is_blue', },
                {text:'随时随地访问您的云空间视频库',type:'is_b_title', },
                {text:"支持手机视频直接上传备份到云端。",type:'is_content', },
                {text:"支持手机端直接访问并播放云端媒体视频库。",type:'is_content', },
              ]
            },
            {
              img:require('@/img/new_mobile04.png'),
              page:'real',
              type:true,// 是否带下载
              direction:'right',// 图片在左or右 left  right
              list_content:[
                {text:'全新!',type:'is_blue', },
                {text:'投射到大屏幕！',type:'is_b_title', },
                {text:"RealPlayer 手机版支持 DLNA 和 Airplay 投屏功能。将手机、电脑媒体库或 RealPlayer 云空间中的视频投屏到电视上，享受完整的画面和声音体验。RealPlayer 移动版是 RealPlayer 粉丝的必备版本，也是适合所有人的绝佳流媒体！",type:'is_content', },
              ]
            },{
              img:require('@/img/new_mobile05.png'),
              page:'real',
              type:true,// 是否带下载
              direction:'left',// 图片在左or右 left  right
              list_content:[
                {text:'全新！',type:'is_blue', },
                {text:"云备份！",type:'is_content', },
                {text:"使用云备份让您的视频内容更安全。",type:'is_content', },
              ]
            }
          ]},
        // 当前设备类型
        type:'', // pc/andriod/ios
      }
    },
    components: {
      AbortReal,DownloadBtn,AbortRealContent
    },
    created:function(){
      window.scrollTo(0,0);
      var type = window.localStorage.getItem('type'); // 获取当前设备类型
      this.type = type;
    },
    methods:{
      openLink:function(url,type=false){
        if(type){
           this.$router.push(url);
        }else{
          window.open(url,'_blank');
        }
      }
    }
}
</script>
<style scoped>
@media screen and (min-width: 480px) {
  
  .mobile-show-title{
    padding-top: 40px;
  }
  .mobile-type{display: none !important;}
  .text1{font: bold 32px Red Hat Display!important;color:#ffbe00 !important;padding-top: 20px !important;}
  .text2{font: bold 45px  Red Hat Display!important;color:#fff !important;}
  .text3{font: normal 15px Red Hat Display!important;color: #525252;}
  .text4{font: normal 16px Red Hat Display!important;}

  .text5{font: bold 22px Red Hat Display!important;padding-top: 8px;padding-bottom: 20px;}
  .text6{font: normal bold 16px/20px Red Hat Display!important;padding-bottom: 25px;color: #ffbe00!important;}
  .text7{font: normal 15px Red Hat Display!important;}
  .text8{font: normal 15px Red Hat Display!important;color: #525252;padding-top: 26px;}
  .banner{
    height:380px;
    box-sizing: border-box;
    text-align: center;
    background-size: 100% 380px; 
    background-repeat: no-repeat	;
  }
  .content{
    background: transparent linear-gradient(180deg,#f8f8f8 0,#eaeaea 100%) 0 0 no-repeat padding-box;
    padding-bottom: 40px;
  }

  .mobile-show{margin:0 auto;padding-top:25px;padding: 25px 16%;background: #0098f1  !important;color: #fff !important;}
  .mobile-show-title{
    font: bold 40px Red Hat Display!important;
    padding-bottom: 20px;
  }
  .mobile-show-content{
    display: flex;
    justify-content: space-between;
  }
  .msc-l{
    flex:1;
    padding-top: 100px;
  }
  .msc-r{width: 50%;display: flex;justify-content: center;align-items: center;}
  .msc-r img{max-height: 262px;}

  /* 二维码 */
  .scancode{width:60%;margin:20px auto 20px;display: flex;justify-content: space-evenly;}
  .scancode-google{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-google img:nth-child(2){width: 110px;height: 110px;}
  .scancode-google img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px 10px;}
  .scancode-appstore{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-appstore img:nth-child(2){width: 110px;height: 110px;}
  .scancode-appstore img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px 10px;}

  .scancode-google1{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-google1 img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px 10px;}
  .scancode-google1 img:nth-child(2){width: 125px;height: 125px;margin: 0 auto;}
  .scancode-appstore1{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-appstore1 img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px 10px;}
  .scancode-appstore1 img:nth-child(2){width: 125px;height: 125px;}
}
@media screen and (min-width: 320px) and (max-width:480px){
  .pc-type{display: none !important;}
  .text1{font: bold 12px Red Hat Display!important;color:#ffbe00 !important;padding-top: 20px !important;}
  .text2{font: bold 22px Red Hat Display!important;color: #fff !important;}
  .text3{font: normal 15px Red Hat Display!important;color: #525252;}
  .text4{font: normal 16px Red Hat Display!important;}

  .text5{font: bold 22px Red Hat Display!important;padding-top: 8px;padding-bottom: 20px;}
  .text6{font: normal bold 16px/20px Red Hat Display!important;padding-bottom: 25px;color: #ffbe00!important;}
  .text7{font: normal 15px Red Hat Display!important;}
  .text8{font: normal 15px Red Hat Display!important;color: #525252;padding-top: 26px;}
  .banner{
    height: 290px;width: 100%;margin: 0 auto;
    padding: 0 5%;
    box-sizing: border-box;
    text-align: center;
    background-size: 100% 290px; 
    background-repeat: no-repeat	;
  }
  .content{
    background: transparent linear-gradient(180deg,#f8f8f8 0,#eaeaea 100%) 0 0 no-repeat padding-box;
    padding-bottom: 40px;

  }

  .mobile-show{margin:0 auto;padding-top:25px;padding: 25px 16%;background: #0098f1  !important;color: #fff !important;}
  .mobile-show-title{
    padding-top: 40px;
  }
  .mobile-show-title{
    font: bold 20px Red Hat Display!important;
    padding-bottom: 20px;
  }
  .msc-l{
    flex:1;
    padding-top: 100px;
  }
  .msc-r img{max-height: 162px;}

  /* 二维码 */
  .scancode{width:60%;margin:20px auto 20px;display: flex;justify-content: space-evenly;}
  .scancode-google{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-google img:nth-child(2){width: 110px;height: 110px;}
  .scancode-google img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px 10px;}
  .scancode-appstore{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-appstore img:nth-child(2){width: 110px;height: 110px;}
  .scancode-appstore img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px 10px;}

  .scancode-google1{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-google1 img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px 10px;}
  .scancode-google1 img:nth-child(2){width: 125px;height: 125px;margin: 0 auto;}
  .scancode-appstore1{cursor: pointer;display: flex;flex-direction: column;align-items: center;}
  .scancode-appstore1 img:nth-child(1){display: block;max-width: 170px;height: 50px;margin:20px 0 10px 10px;}
  .scancode-appstore1 img:nth-child(2){width: 125px;height: 125px;}
}
  

</style>